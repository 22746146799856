export const environment = {
    production: false,
    environmentName: 'staging',
    storagePrefix: 'fsp_web_app',
    title: 'Fire Survey Pro',
    apiUrl: 'https://staging2.api.hickwood.uk/api/v1',
    webPath: 'https://staging2.api.hickwood.uk',
    localPath: 'https://staging2.web.hickwood.uk',
    sentryDSN: 'https://1942885698bd421ba0b878f01d9fa46b@o1040403.ingest.sentry.io/4505156119232512',
    webEnum: '01hrzez5d73snkrggr6wjwtg0f',
    pusher: {
        key: 'e7b31221e2cdea213b9c',
        cluster: 'eu',
    },
    hotjar: {
        siteId: 5143912,
        version: 6
    }
};

import 'zone.js/plugins/zone-error';
